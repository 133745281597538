import React, { useState, Fragment, useEffect } from 'react';

import './styles.scss';

const Modal = ({ title, children, showModal = false, closeModal, classeSize = '' }) => {
    const [show, setShow] = useState(showModal);

    useEffect(() => {
        setShow(showModal);
    }, [showModal]);

    return (
        <Fragment>
          {
            show && 
            <>
                <div className='modal modal-show' role="dialog" aria-labelledby={title}>
                    <div className={"modal-dialog modal-dialog-centered " + classeSize} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{title}</h5>
                                <button type="button" className="close" aria-label="Fechar" onClick={closeModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {children}
                        </div>
                    </div>
                </div>
                <div className='modal-background-block'></div>
            </>
          }
        </Fragment>

    );
}

export default Modal;
